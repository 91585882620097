<template>
  <div class="bb-onboarding">
    <div class="bg-cercles bb-onboarding__container">
      <div class="bb-onboarding__wrapper">
        <div class="bb-onboarding__header bb-onboarding__header--wrap">
          <div class="bb-onboarding__step">{{ $t("onboarding.configuring_your_list") }}: {{ currentStepNumber }}/{{ totalSteps }}</div>
          <div class="bb-onboarding__cover">
            <img src="../../assets/img/ico/ico-cover-favicon-success.svg" alt="" />
          </div>
          <div class="bb-onboarding__title">
            {{ $t("onboarding.you_can_now_begin") }}
          </div>
          <div class="bb-onboarding__description">
            {{ $t("onboarding.we_will_help_you_know_what_you_need") }}
          </div>
        </div>
        <div class="bb-onboarding__content">
          <div class="bb-onboarding__actions">
            <button class="button button--primary button--block">{{ $t("generic.begin") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Steps from "./mixins/Steps";

export default {
  name: "OnboardingStepPuedesEmpezar",
  mixins: [
    Steps,
  ],
};
</script>

<style></style>
