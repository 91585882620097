<template>
  <div class="bb-onboarding">
    <div class="bg-cercles bb-onboarding__container">
      <div class="bb-onboarding__wrapper">
        <div class="bb-onboarding__header bb-onboarding__header--wrap">
          <div class="bb-onboarding__step">Configurando tu lista</div>
          <div class="bb-onboarding__cover">
            <img src="../../assets/img/ico/ico-cover-invitacion-success.svg" alt="" />
          </div>
          <div class="bb-onboarding__title">
            {{ $t("onboarding.we_have_sent_invitation_to", { to: user.invitationText }) }}
          </div>
          <div class="bb-onboarding__description">
            {{ $t("onboarding.once_they_register") }}
          </div>
        </div>
        <div class="bb-onboarding__content">
          <div class="bb-onboarding__actions">
            <button class="button button--primary button--block"
                    @click.prevent="goToNextStep()"
            >
              {{ $t("generic.next") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Steps from "./mixins/Steps";

export default {
  name: "OnboardingStepInvitacionEnviada",
  mixins: [
    Steps,
  ],
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  mounted() {
    console.log(this.user)
  }
};

</script>

<style></style>
