<template>
  <div class="bb-onboarding">
    <div class="bg-cercles bb-onboarding__container">
      <div class="bb-onboarding__wrapper">
        <div class="bb-onboarding__header bb-onboarding__header--wrap">
          <div class="bb-onboarding__step">{{ $t("onboarding.configuring_your_list") }}: {{ currentStepNumber }}/{{ totalSteps }}</div>
          <div class="bb-onboarding__title">{{ $t("onboarding.whyDidYouRegisterTitle") }}</div>
        </div>
        <div class="bb-onboarding__content">
          <section class="bb-onboarding__form">
            <div class="bb-form-fieldset">

              <div class="bb-form-item">
                <textarea
                  :placeholder="$t('onboarding.whyDidYouRegisterPlaceholder')"
                  class="bb-form-textarea"
                  id="response"
                  name="response"
                  v-model="originalResponse"
                />
              </div>

            </div>
          </section>
          <div class="bb-onboarding__actions">
            <loading-button v-if="loading"></loading-button>
            <button v-else @click.prevent="save" class="button button--primary button--block">
              {{ $t("generic.next") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Steps from "./mixins/Steps";

export default {
  name: "OnboardingStepWhyDidYouRegister",
  mixins: [
    Steps,
  ],
  data() {
    return {
      loading: false,
      originalResponse: "",
    };
  },
  computed: {
    cleanResponse() {
      return this.originalResponse.trim();
    },
  },
  methods: {
    async save() {
      this.loading = true;

      try {
        if (this.cleanResponse) {
          // `mixpanel.track` supports passing a callback as additional argument.
          //
          // If at some point we want to wait until that request finishes before
          // going to the next step, we should make use of that callback.
          window.mixpanel.track("¿Cuál es el principal motivo por el que te has registrado en HelloBB?", {
            "response": this.cleanResponse,
          });
        }

        return this.goToNextStep();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
