<template>
  <div class="bb-onboarding">
    <div class="bg-cercles bb-onboarding__container">
      <div class="bb-onboarding__wrapper">
        <div class="bb-onboarding__header">
          <div class="bb-onboarding__step">{{ $t("onboarding.configuring_your_list") }}: {{ currentStepNumber }}/{{ totalSteps }}</div>

          <div class="bb-onboarding__survey">
            <div class="bb-survey">
              <div class="bb-survey__logo">
                <img src="../../assets/img/ico/ico-cover-favicon-success.svg" alt="" />
              </div>
              <div class="bb-onboarding__title">{{ $t("onboarding.do_you_want_to_receive_contributions") }}</div>

              <!--
              TODO: Use CSS to set the max width of this element and let it
              wrap automatically, instead of using a `<br />` for a manual line
              break.
              -->
              <p>
                {{ $t("onboarding.piggybank_explanation_before_linebreak") }}
                <br />
                {{ $t("onboarding.piggybank_explanation_after_linebreak") }}
              </p>

              <div class="bb-survey__items">
                <div class="bb-survey__item">
                  <div class="bb-form-checkbox">
                    <input type="radio" name="checkbox" id="q1" value="contributions" @click="enableButton" />
                    <label for="q1" class="bb-form-checkbox__label">
                      {{ $t("onboarding.contributions_yes") }}
                    </label>
                  </div>
                </div>
                <div class="bb-survey__item">
                  <div class="bb-form-checkbox">
                    <input type="radio" name="checkbox" id="q2" value="no-contributions" @click="enableButton" />
                    <label for="q2" class="bb-form-checkbox__label">
                      {{ $t("onboarding.contributions_no") }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="bb-onboarding__cover">
            <img src="../../assets/img/ico/ico-cover-favicon-success.svg" alt="" />
          </div> -->
        </div>
        <div class="bb-onboarding__content">
          <div class="bb-onboarding__actions">
            <!-- button has to be disabled is there is no check selected -->
            <button
              :disabled="conditionToDisable"
              @click.prevent="next"
              class="button button--primary button--block"
            >
              {{ $t("generic.next") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import Steps from "./mixins/Steps";

export default {
  name: "OnboardingStepSurvey",
  mixins: [
    Steps,
  ],
  data() {
    return {
      conditionToDisable: true,
      selectedOption: null,
    };
  },
  methods: {
    async next() {
      if (this.selectedOption === 'contributions') {
        const updateSuccessful = await this.updateUserFeatureFlag();
        if (!updateSuccessful) {
          return;
        }
      }

      mixpanel.track("Enquesta Onboarding", {
        "response": document.querySelector(
          "input[name=\"checkbox\"]:checked + label"
        ).innerText,
        "question": document.querySelector(".bb-onboarding__title").innerText
      });

      return this.goToNextStep();
    },
    enableButton(event) {
      this.conditionToDisable = false;
      this.selectedOption = event.target.value;
    },
    async updateUserFeatureFlag() {
      try {
        console.log('Updating feature flag...');
        let contributions = true
        const response = await this.$store.dispatch("setContributions", contributions);
        return response.status === 200;

      } catch (error) {
        console.error('Error updating feature flag:', error);
        return false;
      }
    }
  }
};
</script>


<style></style>
